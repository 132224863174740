import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';
import '../../css/NovaChamada.css';

const MySwal = withReactContent(Swal);
export default function NovaChamada() {
    const { context, setContext } = useContext(AppContext);
    const { idEvento } = useParams();

    const [listaChamadas, setListaChamadas] = useState([]);

    const [dadosEvento, setDadosEvento] = useState({ descricao: '', totalRows: 0 });

    async function fetchChamadasEvento() {
        MySwal.close();

        var response = await fetch(`/api/chamadas/${idEvento}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status != 404) {
            var result = await response.json();

            setListaChamadas(result.data?.listaChamadas);
            setDadosEvento({ descricao: result.data.descricao, totalRows: result.data.totalRows });
        }
    }

    async function deletarChamada(idChamada) {
        setContext(old => ({ ...old, isLoading: true }));

        var response = await fetch(`/api/chamadas/${idChamada}`,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status === 200) {
            await fetchChamadasEvento();
        }

        setContext(old => ({ ...old, isLoading: false }));
    }

    useEffect(() => {
        setContext(old => ({ ...old, isLoading: true, urlBotaoVoltar: '/eventos' }));

        fetchChamadasEvento()
            .finally(() => {
                setContext(old => ({ ...old, isLoading: false }));
            });
    }, [])

    function redirectToChamadaParticipantes(idChamada) {
        window.location.href = `/eventos/${idEvento}/chamadas/${idChamada}`;
    }

    const columns = [
        {
            name: 'Data Chamada',
            selector: row => {
                if (!row.dataChamada) return '';

                var dtSplit = row.dataChamada.split("-");

                if (dtSplit.length != 3) return;

                return new Date(dtSplit[0], dtSplit[1] - 1, dtSplit[2]).toLocaleDateString()
            },
            sortable: true
        },
        {
            name: 'Usuário Chamada',
            selector: row => row.usuarioChamada,
            sortable: true
        },
        {
            name: 'Ações',
            selector: row => <>
                <button title="Ver lista de presença" onClick={() => redirectToChamadaParticipantes(row.id)} className="btn btn-primary"><i className="fa fa-users"></i></button>
                <button title="Deletar Chamada" onClick={() => deletarChamada(row.id)} className="ml-2 btn btn-danger"><i className="fa fa-trash"></i></button>
            </>,
        }
    ]

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-users"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Chamadas - {dadosEvento.descricao}</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold logo-color">Lista de Chamadas ({dadosEvento.totalRows})</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive table-cursor-pointer">
                                <DataTable
                                    columns={columns}
                                    data={listaChamadas}
                                    noDataComponent={<>Nenhum registro encontrado</>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}