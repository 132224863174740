import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../contexts/AppContext';
import BotaoVoltar from '../Layouts/BotaoVoltar';

import Avatar from '@mui/material/Avatar';
import { blue, brown, deepOrange, deepPurple, red } from '@mui/material/colors';
import logo from '../../img/ippc.png';
import Protected from '../Protected/Protected';

export default function Layout({ childEl }) {
    const colors = [deepOrange[500], brown[500], deepPurple[500], red[500], blue[500]];

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const btnLogout = useRef();
    const prevOpen = useRef(open);

    const { context, setContext } = useContext(AppContext);

    const doLogout = () => {
        setContext(old => { return { ...old, token: null, username: null, profiles: [] } });
        handleClose();
    };

    const goToChangePassword = () => {
        window.location.href = '/password/reset';
        handleClose();
    };

    const handleToggle = () => setOpen((prevOpen) => !prevOpen);

    const handleClose = (event) => anchorRef.current && anchorRef.current.contains(event?.target) ? null : setOpen(false);

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        } else if (event.key === 'Escape') {
            setOpen(false);
        }
    }

    const getRandomColor = () => {
        if (context.userIconColor)
            return context.userIconColor;

        var randomNum = Math.floor(Math.random() * (colors.length - 1));

        var randomColor = colors[randomNum];

        setContext(curr => ({ ...curr, userIconColor: randomColor }));

        return randomColor
    }

    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;
    }, [open]);

    useEffect(() => {
        if (window.location.pathname === '/')
            setContext(curr => ({ ...curr, isLoading: false }));
    }, []);

    return (
        <>
            <div id="wrapper">

                <ul className="navbar-nav bg-gradient-secondary sidebar sidebar-dark accordion" id="accordionSidebar">

                    <a className="sidebar-brand d-flex align-items-center justify-content-center" href="/">
                        <img src={logo} alt="IPPC Logo" className="ippcHeaderLogo" />
                    </a>

                    <hr className="sidebar-divider my-0" />

                    <Protected context={context} requiredRoles={['administrador']}>
                        <li className="nav-item active">
                            <a className="nav-link" href="/pessoas">
                                <i className="fa fa-users"></i>
                                <span>Pessoas</span></a>
                        </li>

                        <li className="nav-item active">
                            <a className="nav-link" href="/eventos">
                                <i className="fa fa-calendar-days"></i>
                                <span>Eventos</span></a>
                        </li>

                        <li className="nav-item active">
                            <a className="nav-link" href="/relatorios">
                                <i className="fa fa-rectangle-list"></i>
                                <span>Relatórios</span></a>
                        </li>
                    </Protected>
                </ul>

                <div id="content-wrapper" className="d-flex flex-column">

                    <div id="content">

                        <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                            <ul className="navbar-nav d-flex w-100" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                <li className="nav-item dropdown no-arrow">
                                    <Stack direction="row" spacing={2} >
                                        {!context.urlBotaoVoltar ?
                                            <></> :
                                            <BotaoVoltar />
                                        }
                                    </Stack>
                                </li>
                                <li className="nav-item dropdown no-arrow">
                                    <Stack direction="row" spacing={2} >
                                        <div>
                                            <Button
                                                ref={anchorRef}
                                                id="composition-button"
                                                aria-controls={open ? 'composition-menu' : undefined}
                                                aria-expanded={open ? 'true' : undefined}
                                                aria-haspopup="true"
                                                onClick={handleToggle}
                                            >
                                                <Avatar sx={{ bgcolor: getRandomColor(), width: 36, height: 36 }}>
                                                    {context?.username[0]}
                                                </Avatar>
                                            </Button>
                                            <Popper
                                                open={open}
                                                anchorEl={anchorRef.current}
                                                role={undefined}
                                                placement="bottom-start"
                                                transition
                                                disablePortal
                                            >
                                                {({ TransitionProps, placement }) => (
                                                    <Grow
                                                        {...TransitionProps}
                                                        style={{
                                                            transformOrigin:
                                                                placement === 'bottom-start' ? 'left top' : 'left bottom',
                                                        }}
                                                    >
                                                        <Paper>
                                                            <ClickAwayListener onClickAway={handleClose}>
                                                                <MenuList
                                                                    autoFocusItem={open}
                                                                    id="composition-menu"
                                                                    aria-labelledby="composition-button"
                                                                    onKeyDown={handleListKeyDown}
                                                                >
                                                                    {/*<MenuItem onClick={() => goToChangePassword()} style={{ fontSize: '13px' }}>*/}
                                                                    {/*    Change Password*/}
                                                                    {/*</MenuItem>*/}
                                                                    <MenuItem onClick={() => doLogout()} ref={btnLogout} id="btnLogout" style={{ fontSize: '13px' }}>
                                                                        Logout
                                                                    </MenuItem>
                                                                </MenuList>
                                                            </ClickAwayListener>
                                                        </Paper>
                                                    </Grow>
                                                )}
                                            </Popper>
                                        </div>
                                    </Stack>
                                </li>
                            </ul>
                        </nav>
                        <div className="container-fluid" >
                            {childEl}
                        </div>
                    </div>

                    <footer className='footer-layout'>
                        © IPPC 2023 - All rights reserved
                    </footer>
                </div>
            </div>
        </>
    )
}
