import React, { useContext, useEffect } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { AppContext } from '../../contexts/AppContext';

export default function Index() {
    const { context, setContext } = useContext(AppContext);

    useEffect(() => {
        setContext(old => { return { ...old, urlBotaoVoltar: null, isLoading: false } });
    }, []);

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>
        </>
    )
}
