import ChamadaListaPresenca from './components/Chamada/ChamadaListaPresenca';
import Chamadas from './components/Chamada/Chamadas';
import NovaChamada from './components/Chamada/NovaChamada';
import EditarEvento from './components/Eventos/EditarEvento';
import Eventos from './components/Eventos/Eventos';
import NovoEvento from './components/Eventos/NovoEvento';
import EventoParticipantes from './components/Eventos/Participantes';
import Index from './components/Index/Index';
import DetalhesMembro from './components/Membros/DetalhesMembro';
import EditarMembro from './components/Membros/EditarMembro';
import Membros from './components/Membros/Membros';
import NovoMembro from './components/Membros/NovoMembro';
import Relatorio from './components/Relatorios/Relatorio';
import Relatorios from './components/Relatorios/RelatoriosDashboard';

const AppRoutes = [
    {
        path: "/",
        element: <Index />,
        requiredRoles: ['administrador']
    },
    {
        path: "/pessoas",
        element: <Membros />,
        requiredRoles: ['administrador']
    },
    {
        path: "/pessoas/new",
        element: <NovoMembro />,
        requiredRoles: ['administrador']
    },
    {
        path: "/pessoas/:idPessoa/edit",
        element: <EditarMembro />,
        requiredRoles: ['administrador']

    },
    {
        path: "/pessoas/:idPessoa/details",
        element: <DetalhesMembro />,
        requiredRoles: ['administrador']

    },
    {
        path: "/eventos",
        element: <Eventos />,
        requiredRoles: ['administrador']

    },
    {
        path: "/eventos/new",
        element: <NovoEvento />,
        requiredRoles: ['administrador']

    },
    {
        path: "/eventos/:idEvento/chamada/new",
        element: <NovaChamada />,
        requiredRoles: ['administrador']

    },
    {
        path: "/eventos/:idEvento/chamadas",
        element: <Chamadas />,
        requiredRoles: ['administrador']

    },
    {
        path: "/eventos/:idEvento/edit",
        element: <EditarEvento />,
        requiredRoles: ['administrador']

    },
    {
        path: "/eventos/:idEvento/chamadas/:idChamada",
        element: <ChamadaListaPresenca />,
        requiredRoles: ['administrador']

    },
    {
        path: "/eventos/:idEvento/participants",
        element: <EventoParticipantes />,
        requiredRoles: ['administrador']

    },
    {
        path: "/relatorios",
        element: <Relatorios />,
        requiredRoles: ['administrador']

    },
    {
        path: "/relatorios/:idRelatorio",
        element: <Relatorio />,
        requiredRoles: ['administrador']

    }
];

export default AppRoutes;
