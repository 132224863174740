import Login from "../Login/Login";

const Protected = ({ context, requiredRoles, children }) => {
    if (!context.token)
        return <Login />

    if (context.profiles.includes('administrator') || !requiredRoles?.length)
        return children;

    if (!!requiredRoles?.length) {
        const hasRequiredRoles = requiredRoles.some((role) => context.profiles.includes(role));

        // Render a message or redirect the user to a page indicating insufficient privileges
        if (!hasRequiredRoles)
            return <></>;
    }

    return children;
};
export default Protected;