import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { InfinitySpin } from 'react-loader-spinner';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';

const MySwal = withReactContent(Swal);

export default function NovoEvento() {
    const { context, setContext } = useContext(AppContext);

    const [listaMembros, setListaMembros] = useState([]);
    const [tiposEvento, setTiposEvento] = useState([]);

    const [tipoEvento, setTipoEvento] = useState('');
    const [idMinistrador, setIdMinistrador] = useState(null);
    const [descricao, setDescricao] = useState('');
    const [horario, setHorario] = useState('');
    const [dataInicio, setDataInicio] = useState(null);
    const [dataFim, setDataFim] = useState(null);

    const mask = [{ mask: '00:00' }];

    useEffect(() => {
        setContext(old => { return { ...old, isLoading: true, urlBotaoVoltar: '/eventos' } });
        Promise.allSettled([getMembrosList(), fetchTiposEvento()])
            .then(() => {
                setContext(old => { return { ...old, isLoading: false } });
            });
    }, []);

    async function fetchTiposEvento() {
        var response = await fetch(`/api/eventos/tipos`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status == 200) {
            var result = await response.json();

            setTiposEvento(result.tiposEvento)
        }

        setContext(old => ({ ...old, isLoading: false }));
    }

    function getFormErrors() {
        let errors = '';

        if (!tipoEvento) {
            errors += '- Tipo de Evento não preenchido <br/>';
        }

        if (!idMinistrador) {
            errors += '- Moderador não preenchido <br/>';
        }

        if (!descricao) {
            errors += '- Descrição não preenchido <br/>';
        }

        if (!horario) {
            errors += '- Horário não preenchido <br/>';
        }

        if (!dataInicio || !dataInicio.$d) {
            errors += '- Data Inicio Evento não preenchida <br/>';
        }

        if (!dataFim || !dataFim.$d) {
            errors += '- Data Fim Evento não preenchida <br/>';
        }

        return errors;
    }

    async function submitCreateEvento() {
        setContext(old => { return { ...old, isLoading: true } });

        var formErrors = getFormErrors();

        if (!!formErrors) {
            MySwal.fire({
                icon: 'error',
                html: formErrors
            });

            setContext(old => { return { ...old, isLoading: false } });
            return;
        }

        var request = {
            tipoEvento: tipoEvento.value,
            idMinistrador: idMinistrador.value,
            descricao,
            horario,
            dataInicio: dataInicio?.$d?.toJSON(),
            dataFim: dataFim?.$d?.toJSON(),
        };

        setContext(old => { return { ...old, isLoading: true } });

        var response = await fetch(`/api/eventos`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

        var result = await response.json();

        if (response.status !== 200) {

            var errors = result.errors || [];

            var beautifiedErrors = '';

            if (errors && errors.length) {
                beautifiedErrors = '- ' + errors.join('<br/> - ');
            }

            MySwal.fire({
                icon: 'error',
                html: beautifiedErrors || 'Erro interno ao salvar evento'
            });

            setContext(old => { return { ...old, isLoading: false } });
            return;
        }

        MySwal.fire({
            icon: 'success',
            html: result.message
        }).then(() => {
            window.location.href = '/eventos'
        });

        setContext(old => { return { ...old, isLoading: false } });
    }

    async function getMembrosList() {
        var response = await fetch(`/api/membros/list`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status != 200) {
            MySwal.fire({
                icon: 'error',
                html: <>Erro ao obter lista de pessoas.<br />Contate o administrador do sistema.</>
            });
        } else {
            var result = await response.json();

            var membrosList = result.data.map(m => ({ label: m.nome, value: m.id }));

            setListaMembros(membrosList);
        }
    }

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-calendar-plus"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Novo Evento</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <div className="row" style={{ width: '100%' }}>
                                    <div className='col-lg-2 col-12' >
                                        <label>Tipo Evento:</label>
                                        <Select options={tiposEvento} value={tipoEvento} onChange={(e) => setTipoEvento(e)} name="tipo-evento" placeholder="Selecione..." />
                                    </div>
                                    <div className='col-lg-4 col-12 mt-2 mt-lg-0' >
                                        <label>Moderador:</label>
                                        <Select options={listaMembros} value={idMinistrador} onChange={(e) => setIdMinistrador(e)} name="ministrador" placeholder="Selecione..." />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Horário:</label>
                                        <IMaskInput mask={mask} name="horario" placeholder="__:__" className="form-control" onChange={(e) => setHorario(e.currentTarget.value)} value={horario} />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Data Inicio:</label>
                                        <DatePicker onChange={(e) => setDataInicio(e)} value={dataInicio} slotProps={{ textField: { size: 'small' } }} />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Data Fim:</label>
                                        <DatePicker onChange={(e) => setDataFim(e)} value={dataFim} slotProps={{ textField: { size: 'small' } }} />
                                    </div>
                                </div>
                                <div className="row mt-4" style={{ width: '100%' }}>
                                    <div className='col-lg-12 col-12 mt-2 mt-lg-0' >
                                        <label>Descrição:</label>
                                        <input type="text" placeholder="Descrição" className="form-control" value={descricao} onChange={(e) => setDescricao(e.currentTarget.value)} />
                                    </div>
                                </div>
                                <div className="row" style={{ width: '100%', marginTop: '3.5rem' }}>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2 mt-lg-0 text-right' >
                                        <button className="btn btn-danger" onClick={() => window.location.href = '/eventos'}>Cancelar</button>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2 mt-lg-0 text-left' >
                                        <button className="btn btn-success" onClick={() => submitCreateEvento()}>Salvar</button>
                                    </div>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
