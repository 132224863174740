import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { InfinitySpin } from 'react-loader-spinner';
import { MultiSelect } from "react-multi-select-component";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';
import '../../css/Eventos.css';

const MySwal = withReactContent(Swal);

export default function Eventos() {
    const { context, setContext } = useContext(AppContext);

    const [tiposEvento, setTiposEvento] = useState([]);
    const [eventosList, setEventosList] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [perPage, setPerPage] = useState(10);

    const [quantitativo, setQuantitativo] = useState({ totalEventos: 0 });

    const [tipoEventoFiltro, setTipoEventoFiltro] = useState([]);
    const [ministradorFiltro, setMinistradorFiltro] = useState('');
    const [dataEventoFiltro, setDataEventoFiltro] = useState(null);

    async function fetchTiposEvento() {
        var response = await fetch(`/api/eventos/tipos`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status == 200) {
            var result = await response.json();

            setTiposEvento(result.tiposEvento)
        }

        setContext(old => ({ ...old, isLoading: false }));
    }

    async function fetchEventos(page, newPerPage, isUserFiltering) {
        MySwal.close();

        setIsLoadingTable(true);

        var filtersRequest = {
            page,
            perPage: newPerPage,
            ministrador: !ministradorFiltro ? null : ministradorFiltro,
            descricao: null,
            tiposEventos: [0, tiposEvento.length].includes(tipoEventoFiltro.length) ? [] : tipoEventoFiltro.map(x => x.value),
            dataEvento: !dataEventoFiltro || !dataEventoFiltro.$d ? null : dataEventoFiltro.$d.toJSON()
        };

        var response = await fetch(`/api/eventos/dashboard`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filtersRequest)
            });

        if (response.status != 404) {
            var result = await response.json();

            setQuantitativo({
                totalEventos: result.data?.totalRows || 0
            });

            setEventosList(result.data?.eventos || []);

            if (newPerPage) {
                setPerPage(newPerPage);
            }
        }

        setIsLoadingTable(false);
    }

    function redirectToCreateEvento() {
        window.location.href = 'eventos/new'
    }

    function limparFiltros() {
        setTipoEventoFiltro([]);
        setMinistradorFiltro('');
        setDataEventoFiltro(null);
    }

    function redirectToNewChamadaEvento(idEvento) {
        window.location.href = `eventos/${idEvento}/chamada/new`;
    }

    function redirectToChamadasEvento(idEvento) {
        window.location.href = `eventos/${idEvento}/chamadas`;
    }

    function redirectToEditarEvento(idEvento) {
        window.location.href = `eventos/${idEvento}/edit`;
    }

    function redirectToParticipantesEvento(idEvento) {
        window.location.href = `eventos/${idEvento}/participants`;
    }

    async function handlePageChange(page, totalRows) {
        if (!eventosList?.length) {
            return;
        }

        await fetchEventos(page, perPage);
    };

    async function handlePerRowsChange(newPerPage, page, c) {
        if (!eventosList?.length) {
            return;
        }

        if (newPerPage != perPage) {
            setPerPage(newPerPage);
        }

        await fetchEventos(page, newPerPage);
    };

    useEffect(() => {
        setContext(old => ({ ...old, isLoading: true, urlBotaoVoltar: null }));
        fetchTiposEvento();
        fetchEventos(1, perPage);
    }, [])

    const columns = [
        {
            name: 'Tipo',
            selector: row => row.tipoEvento,
            sortable: true,
            sortField: row => row.tipoEvento,
            sortFunction: (a, b) => a.tipoEvento - b.tipoEvento
        },
        {
            name: 'Moderador',
            selector: row => row.ministrador,
            sortable: true
        },
        {
            name: 'Descrição',
            selector: row => row.descricao,
            sortable: true
        },
        {
            name: 'Horario',
            selector: row => row.horario,
            sortable: true
        },
        {
            name: 'Data Inicial',
            selector: row => new Date(row.dataInicio).toLocaleDateString(),
            sortable: true
        },
        {
            name: 'Data Final',
            selector: row => new Date(row.dataFim).toLocaleDateString(),
            sortable: true
        },
        {
            name: 'Ações',
            grow: 2,
            selector: row => <>
                <button title="Cadastrar Participantes" className="btn btn-success mr-3" onClick={() => redirectToParticipantesEvento(row.id)}><i className="fa fa-user-plus"></i></button>
                <button title="Nova Chamada" className="btn btn-warning mr-3" onClick={() => redirectToNewChamadaEvento(row.id)}><i className="fa fa-list-check"></i></button>
                <button title="Chamadas" className="btn btn-secondary mr-3" onClick={() => redirectToChamadasEvento(row.id)}><i className="fa fa-list-alt"></i></button>
                <button title="Editar" className="btn btn-primary" onClick={() => redirectToEditarEvento(row.id)}><i className="fa fa-pencil"></i></button>
            </>,
            sortable: false
        }
    ];

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-calendar-days"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Eventos</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <div className="row">
                                    <div className='col-lg-3 col-12' >
                                        <label>Tipo Evento:</label>
                                        <MultiSelect
                                            disableSearch={true}
                                            options={tiposEvento}
                                            labelledBy="Selecione..."
                                            hasSelectAll={false}
                                            valueRenderer={(selected) => !selected?.length ? 'Selecione...' : (selected.length == 1 ? selected[0].label : (selected.length == tiposEvento.length ? `Todos` : `${selected[0].label}, +${selected.length - 1}`))}
                                            className="multiselect-prevent-resize select-evento-page"
                                            value={tipoEventoFiltro}
                                            onChange={(e) => {
                                                const collator = new Intl.Collator('pt-BR');
                                                var sortedList = e.sort((a, b) => collator.compare(a.label, b.label));
                                                setTipoEventoFiltro(sortedList);
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-3 col-12 mt-2 mt-lg-0' >
                                        <label>Moderador:</label>
                                        <input type="text" placeholder="Moderador do Evento" className="form-control" onChange={(e) => setMinistradorFiltro(e.currentTarget.value)} value={ministradorFiltro} />
                                    </div>
                                    <div className='col-lg-3 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label style={{ whiteSpace: 'nowrap' }}>Data Evento:</label>
                                        <DatePicker onChange={(e) => setDataEventoFiltro(e)} className="date-picker-w-100" slotProps={{ textField: { size: 'small' } }} value={dataEventoFiltro} />
                                    </div>
                                    <div className='col-12 mt-4 text-center text-lg-right' >
                                        <button className="btn btn-danger mr-md-2" onClick={() => limparFiltros()}><i className="fa fa-plus"></i> Limpar filtros</button>
                                        <button className="btn btn-secondary mr-md-2" onClick={() => redirectToCreateEvento()}><i className="fa fa-plus"></i> Novo Evento</button>
                                        <button className="btn btn-primary mt-2 mt-md-0" onClick={() => fetchEventos(1, perPage, true)}>Filtrar</button>
                                    </div>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold logo-color">Tabela Analitica ({quantitativo.totalEventos})</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={eventosList}
                                    progressPending={isLoadingTable}
                                    progressComponent={<>Carregando...</>}
                                    pagination
                                    paginationServer
                                    noDataComponent={<>Nenhum registro encontrado</>}
                                    paginationTotalRows={quantitativo.totalEventos}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationComponentOptions={{ rowsPerPageText: 'Linhas por página', rangeSeparatorText: 'de' }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
