import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { InfinitySpin } from 'react-loader-spinner';
import { AppContext } from '../../contexts/AppContext';

export default function RelatoriosDashboard() {
    const { context, setContext } = useContext(AppContext);

    const [relatoriosInfo, setRelatoriosInfo] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [perPage, setPerPage] = useState(10);

    async function fetchRelatorios(page, newPerPage) {
        setIsLoadingTable(true);

        var response = await fetch(`/api/relatorios?perPage=${newPerPage}&page=${page}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status === 200) {
            var result = await response.json();

            setRelatoriosInfo(result);

            if (newPerPage) {
                setPerPage(newPerPage);
            }
        }

        setIsLoadingTable(false);
    }

    function redirectToRelatorioPage(idRelatorio) {
        window.location.href = `relatorios/${idRelatorio}`;
    }

    async function handlePageChange(page, totalRows) {
        if (!relatoriosInfo?.relatorios?.length) {
            return;
        }

        await fetchRelatorios(page, perPage);
    };

    async function handlePerRowsChange(newPerPage, page, c) {
        if (!relatoriosInfo?.relatorios?.length) {
            return;
        }

        if (newPerPage != perPage) {
            setPerPage(newPerPage);
        }

        await fetchRelatorios(page, newPerPage);
    };

    useEffect(() => {
        setContext(old => { return { ...old, urlBotaoVoltar: null } });
        fetchRelatorios(1, perPage);
    }, []);

    const columns = useMemo(() => [
        {
            name: 'Nome',
            sortable: true,
            selector: row => row.nome
        },
        {
            name: 'Descrição',
            sortable: true,
            selector: row => row.descricao
        },
        {
            name: 'Ações',
            selector: row => {
                return <>
                    <button
                        title="Gerar Relatório"
                        className={"btn btn-primary"}
                        onClick={() => redirectToRelatorioPage(row.id)}>
                        <i className="fa fa-list-alt"></i>
                    </button>
                </>
            },
            sortable: false
        }
    ], []);

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-list-alt"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Relatórios</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold logo-color">Tabela Analitica ({relatoriosInfo.totalRows})</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={relatoriosInfo.tiposRelatorios}
                                    progressPending={isLoadingTable}
                                    progressComponent={<>Carregando...</>}
                                    pagination
                                    paginationServer
                                    noDataComponent={<>Nenhum registro encontrado</>}
                                    paginationTotalRows={relatoriosInfo.totalRows}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationComponentOptions={{ rowsPerPageText: 'Linhas por página', rangeSeparatorText: 'de' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}