import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import { AppContext } from '../../contexts/AppContext';

export default function Relatorio() {
    const { context, setContext } = useContext(AppContext);

    const { idRelatorio } = useParams();

    const [componenteRelatorio, setComponentRelatorio] = useState(<></>);

    const cardBody = useRef(null);

    async function fetchDadosRelatorio() {
        setContext(old => { return { ...old, isLoading: true, urlBotaoVoltar: null } });

        let response = await fetch(`/api/relatorios/${idRelatorio}`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        let result = '';

        if (response.status === 200) {
            result = await response.json();

            setComponentRelatorio(<iframe title={"Relatório"}
                src={result}
                width={cardBody.current.clientWidth}
                height={cardBody.current.clientHeight - (cardBody.current.clientHeight / 10)}
            />)
        }

        setContext(old => { return { ...old, isLoading: false } });
        return result;
    }

    useEffect(() => {
        fetchDadosRelatorio().then(metabaseEndPoint => {
            window.addEventListener('resize', () => {
                setComponentRelatorio(<iframe title={"Relatório"}
                    src={metabaseEndPoint}
                    width={cardBody.current.clientWidth}
                    height={cardBody.current.clientHeight - (cardBody.current.clientHeight / 10)}
                />)
            });
        });

        setContext(old => ({ ...old, urlBotaoVoltar: null }));
    }, []);

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-list-alt"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Relatórios</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0" style={{ height: '80vh' }}>
                        <div className="card-body" ref={cardBody}>
                            <div className="table-responsive" style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
                                {componenteRelatorio}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}