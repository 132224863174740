import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';
import '../../css/NovaChamada.css';

const MySwal = withReactContent(Swal);

export default function ChamadaListaPresenca() {
    const { context, setContext } = useContext(AppContext);
    const { idChamada, idEvento } = useParams();

    const [listaPresenca, setListaPresenca] = useState([]);

    const [dadosEvento, setDadosEvento] = useState({ descricao: '', dataChamada: '', usuarioChamada: '', totalRows: 0 });

    async function fetchChamadasEvento() {
        setContext(old => { return { ...old, isLoading: true } });
        MySwal.close();

        var response = await fetch(`/api/chamadas/${idChamada}/participantes`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status === 200) {
            var result = await response.json();

            if (result) {
                var { dataChamada, descricao, totalRows, usuarioChamada, listaChamadas } = result.data;

                setListaPresenca(listaChamadas);

                if (dataChamada) {
                    var dtSplit = dataChamada.split("-");

                    if (dtSplit.length == 3) {
                        dataChamada = new Date(dtSplit[0], dtSplit[1] - 1, dtSplit[2]).toLocaleDateString();
                    }
                }

                setDadosEvento({ dataChamada, descricao, totalRows, usuarioChamada });
            }
        }

        setContext(old => { return { ...old, isLoading: false } });
    }

    async function alterarPresencaPessoa(idPessoa) {
        setContext(old => { return { ...old, isLoading: true } });
        MySwal.close();

        var response = await fetch(`/api/chamadas/${idChamada}/${idPessoa}`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status === 200) {
            let oListaPresenca = listaPresenca.slice();
            let participante = oListaPresenca.find(l => l.idPessoa == idPessoa);
            participante.flPresente = !participante.flPresente;
            setListaPresenca(oListaPresenca);
        } else {
            MySwal.fire({
                icon: 'error',
                html: <>Erro ao atualizar presença da pessoa.<br />Contate o administrador do sistema.</>
            });
        }

        setContext(old => { return { ...old, isLoading: false } });
    }

    useEffect(() => {
        fetchChamadasEvento();
        setContext(old => { return { ...old, urlBotaoVoltar: `/eventos/${idEvento}/chamadas` } });
    }, [])

    const columns = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true
        },
        {
            name: 'Estava presente?',
            selector: row => <button disabled className={`btn btn-${!row.flPresente ? 'danger' : 'success'}`}>{row.flPresente === true ? 'Presente' : 'Ausente'}</button>,
            sortable: true
        },
        {
            name: 'Ações',
            selector: row => <button onClick={() => alterarPresencaPessoa(row.idPessoa)} className={`btn btn-${row.flPresente ? 'danger' : 'success'}`}>Marcar como {row.flPresente !== true ? 'Presente' : 'Ausente'}</button>,
            sortable: false
        }

    ]

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="row mb-4">
                    <h1 className="h3 mb-0 text-gray-800"><i className="fa fa-users"></i>&nbsp;&nbsp; Lista de presença - {dadosEvento.descricao}</h1>
                    <h6 className="ml-5">{dadosEvento.dataChamada}</h6>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold logo-color">Lista de Matrículas ({dadosEvento.totalRows})</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={listaPresenca}
                                    noDataComponent={<>Nenhum registro encontrado</>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}