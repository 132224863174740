import React, { useContext, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { IMaskInput } from 'react-imask';
import { InfinitySpin } from 'react-loader-spinner';
import { MultiSelect } from "react-multi-select-component";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';
import '../../css/Membros.css';
import ExportCsv from '../Utils/ExportCsv';

const MySwal = withReactContent(Swal);

export default function Membros() {
    const { context, setContext } = useContext(AppContext);

    const [tiposList, setTiposList] = useState({
        tiposMembro: [],
        tiposGenero: [],
        tiposEstadoCivil: []
    });

    const [membrosList, setMembrosList] = useState([]);
    const [isLoadingTable, setIsLoadingTable] = useState(true);
    const [perPage, setPerPage] = useState(10);

    const [quantitativo, setQuantitativo] = useState({ totalMembros: 0 });

    const [idadeInicialFiltro, setIdadeInicialFiltro] = useState(null);
    const [idadeFinalFiltro, setIdadeFinalFiltro] = useState(null);
    const [mesesAniversarioFiltro, setMesesAniversarioFiltro] = useState([]);
    const [tipoMembroFiltro, setTipoMembroFiltro] = useState([]);
    const [estadoCivilFiltro, setEstadoCivilFiltro] = useState([]);
    const [sexoFiltro, setSexoFiltro] = useState([]);
    const [membroProfessoFiltro, setMembroProfessoFiltro] = useState([]);
    const [nomeFiltro, setNomeFiltro] = useState('');

    const maskIdade = useMemo(() => [{ mask: '000' }], []);

    const listaMeses = useMemo(() =>
        [
            { value: 1, label: 'Janeiro' },
            { value: 2, label: 'Fevereiro' },
            { value: 3, label: 'Março' },
            { value: 4, label: 'Abril' },
            { value: 5, label: 'Maio' },
            { value: 6, label: 'Junho' },
            { value: 7, label: 'Julho' },
            { value: 8, label: 'Agosto' },
            { value: 9, label: 'Setembro' },
            { value: 10, label: 'Outubro' },
            { value: 11, label: 'Novembro' },
            { value: 12, label: 'Dezembro' },
        ],
        []);

    const membroProfessoOptions = useMemo(() => [
        { label: 'Não', value: false },
        { label: 'Sim', value: true }
    ], []);


    useEffect(() => {
        setContext(old => ({ ...old, isLoading: true, urlBotaoVoltar: null }));
        fetchMembros(1, perPage);
        fetchTipos();
    }, []);

    async function fetchMembros(page, newPerPage, isUserFiltering) {
        if (isUserFiltering) {
            const errors = await validateFilters();

            if (!!errors) {
                MySwal.fire({
                    icon: 'error',
                    html: errors
                });
                return;
            }
        }

        MySwal.close();

        setIsLoadingTable(true);

        var filtersRequest = {
            page,
            perPage: newPerPage,
            nome: !nomeFiltro ? null : nomeFiltro,
            membroProfesso: [0, membroProfessoOptions.length].includes(membroProfessoFiltro.length) ? null : membroProfessoFiltro[0].value,
            estadosCivis: [0, tiposList.tiposEstadoCivil.length].includes(estadoCivilFiltro.length) ? [] : estadoCivilFiltro.map(x => x.value),
            tiposMembros: [0, tiposList.tiposMembro.length].includes(tipoMembroFiltro.length) ? [] : tipoMembroFiltro.map(x => x.value),
            generos: [0, tiposList.tiposGenero.length].includes(sexoFiltro.length) ? [] : sexoFiltro.map(x => x.value),
            mesesAniversario: [0, listaMeses.length].includes(mesesAniversarioFiltro.length) ? [] : mesesAniversarioFiltro.map(x => x.value),
            faixaEtariaInicio: idadeInicialFiltro,
            faixaEtariaFim: idadeFinalFiltro
        };

        var response = await fetch(`/api/membros/dashboard`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(filtersRequest)
            });

        if (response.status != 404) {
            var result = await response.json();

            setQuantitativo({
                totalMembros: result.data?.totalRows || 0
            });

            setMembrosList(result.data?.membros || []);

            if (newPerPage) {
                setPerPage(newPerPage);
            }
        }
        setIsLoadingTable(false);
    }

    async function fetchTipos() {
        try {
            var response = await fetch(`/api/membros/tipos`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${context.token}`,
                        'Content-Type': 'application/json'
                    }
                });

            if (response.status == 200) {
                var result = await response.json();

                if (result != null) {
                    let tiposPessoa = result.tiposPessoa;
                    let generos = result.generos;
                    let estadosCivis = result.estadosCivis;

                    if (!!tiposPessoa?.length || !!generos?.length || !!estadosCivis?.length) {
                        setTiposList({
                            tiposMembro: tiposPessoa ?? [],
                            tiposGenero: generos ?? [],
                            tiposEstadoCivil: estadosCivis ?? []
                        });
                    }
                }
            }
        }
        finally {
            setContext(old => ({ ...old, isLoading: false }));
        }
    }

    async function validateFilters() {
        if (!!idadeInicialFiltro && !idadeFinalFiltro) {
            return <>Idade <b>Máxima</b> não preenchida.<br /><br />É necessário preenchê-la ou limpar a Idade Mínima.</>
        }

        if (!idadeInicialFiltro && !!idadeFinalFiltro) {
            return <>Idade <b>Mínima</b> não preenchida.<br /><br />É necessário preenchê-la ou limpar a Idade Máxima.</>
        }

        if (!!idadeInicialFiltro && parseInt(idadeInicialFiltro) < 0) {
            return <>Idade Mínima/Máxima não podem ser negativas.</>
        }

        if (!!nomeFiltro && nomeFiltro.replace(/\s/g, '').length <= 3) {
            return <>Nome deve conter pelo menos 3 caracteres válidos para filtragem.</>
        }
    }

    async function toggleAtivaInativaMembro(idMembro) {
        setContext(old => { return { ...old, isLoading: true } });

        var response = await fetch(`/api/membros/${idMembro}/toggle`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        var result = await response.json();

        if (response.status !== 200) {

            var errors = result.errors || [];

            var beautifiedErrors = '';

            if (errors && errors.length) {
                beautifiedErrors = '- ' + errors.join('<br/> - ');
            }

            MySwal.fire({
                icon: 'error',
                html: beautifiedErrors || 'Erro interno ao salvar membro'
            });

            setContext(old => { return { ...old, isLoading: false } });
            return;
        }

        MySwal.fire({
            icon: 'success',
            html: result.message,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            toast: true
        });

        let oListaMembros = membrosList.slice();

        let membro = oListaMembros.find(l => l.id == idMembro);
        membro.flAtivo = !membro.flAtivo;
        setMembrosList(oListaMembros);

        setContext(old => { return { ...old, isLoading: false } });
    }

    function limparFiltros() {
        setIdadeInicialFiltro(null);
        setIdadeFinalFiltro(null);
        setMesesAniversarioFiltro(null);
        setTipoMembroFiltro([]);
        setEstadoCivilFiltro([]);
        setSexoFiltro([]);
        setMembroProfessoFiltro([]);
        setNomeFiltro('');
    }

    function redirectToCreateMembro() {
        window.location.href = 'pessoas/new'
    }

    async function handlePageChange(page, totalRows) {
        if (!membrosList?.length) {
            return;
        }

        await fetchMembros(page, perPage);
    };

    async function handlePerRowsChange(newPerPage, page, c) {
        if (!membrosList?.length) {
            return;
        }

        if (newPerPage != perPage) {
            setPerPage(newPerPage);
        }

        await fetchMembros(page, newPerPage);
    };

    function copyToClipboard(nomeCampo, valorCopiado) {
        navigator.clipboard.writeText(valorCopiado)

        MySwal.fire({
            icon: 'success',
            html: `${nomeCampo} copiado para área de transferência com sucesso!`,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            toast: true
        });
    }

    const columns = [
        {
            name: 'Nome',
            selector: row => <span style={{ cursor: 'pointer' }} onClick={() => copyToClipboard('Nome', row.nome)}>{row.nome}</span>,
            sortable: true
        },
        {
            name: 'Tipo',
            hide: 'md',
            selector: row => row.tipoPessoa,
            sortable: true,
            sortField: row => row.tipoPessoa,
            sortFunction: (a, b) => a.tipoPessoa - b.tipoPessoa
        },
        {
            name: 'Dt Aniversario',
            selector: row => {
                var dataNascimento = new Date(row.dataNascimento);

                return (dataNascimento.getDate()).toString().padStart(2, '0') + "/" + (dataNascimento.getMonth() + 1).toString().padStart(2, '0');
            },
            sortable: true,
            sortFunction: (a, b) => {
                var dataNascA = new Date(a.dataNascimento);
                var dataNascB = new Date(b.dataNascimento);

                dataNascA.setFullYear(2000);
                dataNascB.setFullYear(2000);

                return dataNascA - dataNascB;
            },
            hide: 'md'
        },
        {
            name: 'Email',
            selector: row => <span style={{ cursor: 'pointer' }} onClick={() => copyToClipboard('Email', row.email)}>{row.email}</span>,
            sortable: true,
            hide: 'md',
            sortField: row => row.email,
            sortFunction: (a, b) => a.email - b.email
        },
        {
            name: 'Tel Celular',
            hide: 'md',
            selector: row => <span style={{ cursor: 'pointer' }} onClick={() => copyToClipboard('Telefone Celular', row.telefoneCelular)}>{row.telefoneCelular}</span>,
            sortable: true,
            sortFunction: (a, b) => a.telefoneCelular - b.telefoneCelular
        },
        {
            name: 'Status',
            selector: row => row.flAtivo === true ? <button style={{ width: '77px' }} disabled className="btn btn-success">Ativo</button> : <button style={{ width: '77px' }} disabled className="btn btn-danger">Inativo</button>,
            sortable: true,
            sortField: row => row.flAtivo,
            sortFunction: (a, b) => a.flAtivo - b.flAtivo
        },
        {
            name: 'Ações',
            selector: row => row.flAtivo ?
                <>
                    <button title="Inativar Membro" className="btn btn-danger mr-3" onClick={() => toggleAtivaInativaMembro(row.id)} ><i className="fa fa-toggle-off"></i></button>
                    <button title="Detalhes do membro" className="btn btn-secondary mr-3" onClick={() => window.location.href = `/pessoas/${row.id}/details`} ><i className="fa fa-list-alt"></i></button>
                    <button title="Editar membro" className="btn btn-primary" onClick={() => window.location.href = `/pessoas/${row.id}/edit`} ><i className="fa fa-pencil"></i></button>
                </>
                :
                <>
                    <button title="Reativar Membro" className="btn btn-success mr-3" onClick={() => toggleAtivaInativaMembro(row.id)} ><i className="fa fa-toggle-on"></i></button>
                    <button title="Detalhes do membro" className="btn btn-secondary" onClick={() => window.location.href = `/pessoas/${row.id}/details`} ><i className="fa fa-list-alt"></i></button>
                </>,
            grow: 2,
            sortable: false
        }
    ];

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-users"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Pessoas</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <div className="row">
                                <div className='col-lg-2 col-12' >
                                    <label>Tipo Pessoa:</label>
                                    <MultiSelect
                                        options={tiposList.tiposMembro}
                                        labelledBy="Selecione..."
                                        hasSelectAll={false}
                                        valueRenderer={(selected) => !selected?.length ? 'Selecione...' : (selected.length == 1 ? selected[0].label : (selected.length == tiposList.tiposMembro.length ? `Todos` : `${selected[0].label}, +${selected.length - 1}`))}
                                        className="multiselect-prevent-resize"
                                        value={tipoMembroFiltro}
                                        onChange={(e) => {
                                            const collator = new Intl.Collator('pt-BR');
                                            var sortedList = e.sort((a, b) => collator.compare(a.label, b.label));
                                            setTipoMembroFiltro(sortedList);
                                        }}
                                    />
                                </div>
                                <div className='col-lg-2 col-12 mt-2 mt-lg-0' >
                                    <label>Nome:</label>
                                    <input type="text" placeholder="Nome" className="form-control" value={nomeFiltro} onChange={(e) => {
                                        let nome = e.currentTarget.value;
                                        setNomeFiltro(nome)
                                    }} />
                                </div>
                                <div className='col-lg-2 col-12 mt-2 mt-lg-0' >
                                    <label>Gênero:</label>
                                    <MultiSelect
                                        disableSearch={true}
                                        options={tiposList.tiposGenero}
                                        labelledBy="Selecione..."
                                        hasSelectAll={false}
                                        valueRenderer={(selected) => !selected?.length ? 'Selecione...' : (selected.length == 1 ? selected[0].label : (selected.length == tiposList.tiposGenero.length ? `Todos` : `${selected[0].label}, +${selected.length - 1}`))}
                                        className="multiselect-prevent-resize"
                                        value={sexoFiltro}
                                        onChange={(e) => {
                                            const collator = new Intl.Collator('pt-BR');
                                            var sortedList = e.sort((a, b) => collator.compare(a.label, b.label));
                                            setSexoFiltro(sortedList);
                                        }}
                                    />
                                </div>
                                <div className='col-lg-2 col-12 mt-2 mt-lg-0' >
                                    <label>É Professo?</label>
                                    <MultiSelect
                                        disableSearch={true}
                                        options={membroProfessoOptions}
                                        labelledBy="Selecione..."
                                        hasSelectAll={false}
                                        valueRenderer={(selected) => !selected?.length ? 'Selecione...' : (selected.length == 1 ? selected[0].label : (selected.length == membroProfessoOptions.length ? `Todos` : `${selected[0].label}, +${selected.length - 1}`))}
                                        className="multiselect-prevent-resize"
                                        value={membroProfessoFiltro}
                                        onChange={(e) => {
                                            const collator = new Intl.Collator('pt-BR');
                                            var sortedList = e.sort((a, b) => collator.compare(a.label, b.label));
                                            setMembroProfessoFiltro(sortedList);
                                        }}
                                    />
                                </div>
                                <div className='col-lg-2 col-12' >
                                    <label>Estado Civil:</label>
                                    <MultiSelect
                                        options={tiposList.tiposEstadoCivil}
                                        labelledBy="Selecione..."
                                        hasSelectAll={false}
                                        valueRenderer={(selected) => !selected?.length ? 'Selecione...' : (selected.length == 1 ? selected[0].label : (selected.length == tiposList.tiposEstadoCivil.length ? `Todos` : `${selected[0].label}, +${selected.length - 1}`))}
                                        className="multiselect-prevent-resize"
                                        value={estadoCivilFiltro}
                                        onChange={(e) => {
                                            const collator = new Intl.Collator('pt-BR');
                                            var sortedList = e.sort((a, b) => collator.compare(a.label, b.label));
                                            setEstadoCivilFiltro(sortedList);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row mt-4">
                                <div className='col-lg-1 col-12'>
                                    <label>Idade:</label>
                                    <IMaskInput
                                        mask={maskIdade}
                                        name="idade-minima"
                                        placeholder="Mín"
                                        className="form-control"
                                        onChange={(e) => {
                                            let idadeInicial = e.currentTarget.value;
                                            setIdadeInicialFiltro(idadeInicial);
                                        }}
                                        value={idadeInicialFiltro}
                                    />
                                </div>
                                <div className='col-lg-1 col-12'>
                                    <br />
                                    <IMaskInput
                                        mask={maskIdade}
                                        name="idade-maxima"
                                        placeholder="Máx"
                                        className="form-control"
                                        onChange={(e) => {
                                            let idadeFinal = e.currentTarget.value;
                                            setIdadeFinalFiltro(idadeFinal);
                                        }}
                                        value={idadeFinalFiltro}
                                    />
                                </div>

                                <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                    <label style={{ whiteSpace: 'nowrap' }}>Mês de Aniversario:</label>
                                    <MultiSelect
                                        options={listaMeses}
                                        labelledBy="Selecione..."
                                        hasSelectAll={false}
                                        valueRenderer={(selected) => !selected?.length ? 'Selecione...' : (selected.length == 1 ? selected[0].label : (selected.length == tiposList.tiposEstadoCivil.length ? `Todos` : `${selected[0].label}, +${selected.length - 1}`))}
                                        className="multiselect-prevent-resize"
                                        value={mesesAniversarioFiltro}
                                        onChange={(e) => {
                                            const collator = new Intl.Collator('pt-BR');
                                            var sortedList = e.sort((a, b) => collator.compare(a.label, b.label));
                                            setMesesAniversarioFiltro(sortedList);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className='col-12 mt-4 text-center text-lg-right' >
                                    <button className="btn btn-danger mr-md-2" onClick={() => limparFiltros()}><i className="fa fa-plus"></i> Limpar filtros</button>
                                    <button className="btn btn-secondary mr-md-2" onClick={() => redirectToCreateMembro()}><i className="fa fa-plus"></i> Nova Pessoa</button>
                                    <button className="btn btn-primary mt-2 mt-md-0" onClick={() => fetchMembros(1, perPage, true)}>Filtrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold logo-color">Tabela Analitica ({quantitativo.totalMembros})</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={membrosList}
                                    progressPending={isLoadingTable}
                                    progressComponent={<>Carregando...</>}
                                    pagination
                                    paginationServer
                                    paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                    noDataComponent={<>Nenhum registro encontrado</>}
                                    paginationTotalRows={quantitativo.totalMembros}
                                    onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    actions={<ExportCsv data={membrosList} />}
                                    paginationComponentOptions={{ rowsPerPageText: 'Linhas por página', rangeSeparatorText: 'de' }}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
