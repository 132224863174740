import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';

const MySwal = withReactContent(Swal);

export default function EditarEvento() {
    const { context, setContext } = useContext(AppContext);

    const { idEvento } = useParams();

    const [dadosEvento, setDadosEvento] = useState({
        id: '',
        ministradorNome: '',
        tipoEvento: '',
        horario: '',
        dataInicio: '',
        dataFim: '',
        descricao: ''
    })

    const [descricaoInicial, setDescricaoInicial] = useState('');

    const mask = [{ mask: '00:00' }];

    useEffect(() => {
        fetchEvento();
    }, []);

    async function fetchEvento() {
        MySwal.close();
        setContext(old => { return { ...old, isLoading: true } });

        var response = await fetch(`/api/eventos/${idEvento}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status === 200) {
            var result = await response.json();

            setDadosEvento({
                id: result.data?.id,
                ministradorNome: result.data?.ministrador,
                tipoEvento: result.data?.tipoEvento,
                horario: result.data?.horario,
                dataInicio: dayjs(result.data?.dataInicio),
                dataFim: dayjs(result.data?.dataFim),
                descricao: result.data?.descricao
            });

            setDescricaoInicial(result.data?.descricao);
        }

        setContext(old => { return { ...old, isLoading: false } });
    }

    function getFormErrors() {
        let errors = '';

        if (!dadosEvento.descricao) {
            errors += '- Descrição não preenchido <br/>';
        }

        if (!dadosEvento.horario) {
            errors += '- Horário não preenchido <br/>';
        }

        if (!dadosEvento.dataInicio || !dadosEvento.dataInicio.$d) {
            errors += '- Data Inicio Evento não preenchida <br/>';
        }

        if (!dadosEvento.dataFim || !dadosEvento.dataFim.$d) {
            errors += '- Data Fim Evento não preenchida <br/>';
        }

        return errors;
    }

    async function submitCreateEvento() {
        setContext(old => { return { ...old, isLoading: true } });

        var formErrors = getFormErrors();

        if (!!formErrors) {
            MySwal.fire({
                icon: 'error',
                html: formErrors
            });

            setContext(old => { return { ...old, isLoading: false } });
            return;
        }

        var request = {
            descricao: dadosEvento.descricao,
            horario: dadosEvento.horario,
            dataInicio: dadosEvento.dataInicio?.$d?.toJSON(),
            dataFim: dadosEvento.dataFim?.$d?.toJSON(),
        };

        var response = await fetch(`/api/eventos/${idEvento}`,
            {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

        var result = await response.json();

        if (response.status !== 200) {

            var errors = result.errors || [];

            var beautifiedErrors = '';

            if (errors && errors.length) {
                beautifiedErrors = '- ' + errors.join('<br/> - ');
            }

            MySwal.fire({
                icon: 'error',
                html: beautifiedErrors || 'Erro interno ao salvar evento'
            });

            setContext(old => { return { ...old, isLoading: false } });
            return;
        }

        MySwal.fire({
            icon: 'success',
            html: result.message
        }).then(() => {
            window.location.href = '/eventos'
        });

        setContext(old => { return { ...old, isLoading: false } });
    }

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-calendar-plus"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Editar Evento - {descricaoInicial}</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <div className="row" style={{ width: '100%' }}>
                                    <div className='col-lg-2 col-12' >
                                        <label>Tipo Evento:</label>
                                        <input type="text" value={dadosEvento.tipoEvento} className="form-control" disabled />
                                    </div>
                                    <div className='col-lg-4 col-12 mt-2 mt-lg-0' >
                                        <label>Moderador:</label>
                                        <input type="text" value={dadosEvento.ministradorNome} className="form-control" disabled />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Horário:</label>
                                        <IMaskInput mask={mask} name="horario" placeholder="__:__" className="form-control" onChange={(e) => setDadosEvento(de => ({ ...de, horario: e.target.value }))} value={dadosEvento.horario} />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Data Inicio:</label>
                                        <DatePicker onChange={(e) => setDadosEvento(de => ({ ...de, dataInicio: e }))} value={dadosEvento.dataInicio} slotProps={{ textField: { size: 'small' } }} />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Data Fim:</label>
                                        <DatePicker onChange={(e) => setDadosEvento(de => ({ ...de, dataFim: e }))} value={dadosEvento.dataFim} slotProps={{ textField: { size: 'small' } }} />
                                    </div>
                                </div>
                                <div className="row mt-4" style={{ width: '100%' }}>
                                    <div className='col-lg-12 col-12 mt-2 mt-lg-0' >
                                        <label>Descrição:</label>
                                        <input type="text" placeholder="Descrição" className="form-control" value={dadosEvento.descricao} onChange={(e) => setDadosEvento(de => ({ ...de, descricao: e.target.value }))} />
                                    </div>
                                </div>
                                <div className="row" style={{ width: '100%', marginTop: '3.5rem' }}>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2 mt-lg-0 text-right' >
                                        <button className="btn btn-danger" onClick={() => window.location.href = '/eventos'}>Cancelar</button>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2 mt-lg-0 text-left' >
                                        <button className="btn btn-success" onClick={() => submitCreateEvento()}>Salvar</button>
                                    </div>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
