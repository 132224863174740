import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import '../../css/Membros.css';

export default function DetalhesMembro() {
    const { context, setContext } = useContext(AppContext);

    const { idPessoa } = useParams();

    const [dadosPessoa, setDadosPessoa] = useState({
        tipoMembro: '',
        nome: '',
        sexo: '',
        nacionalidade: '',
        naturalidade: '',
        dataNascimento: '',
        profissao: '',
        cep: '',
        logradouro: '',
        numeroLogradouro: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
        email: '',
        telefoneCelular: '',
        telefoneFixo: '',
        estadoCivil: '',
        flAlfabetizado: '',
        flMembroProfesso: '',
        flMembroMatriz: '',
        flAtivo: false
    });

    useEffect(() => {
        setContext(old => { return { ...old, urlBotaoVoltar: '/pessoas' } });
        fetchDadosPessoa();
    }, []);

    async function fetchDadosPessoa() {
        setContext(old => { return { ...old, isLoading: true } });

        var response = await fetch(`/api/membros/${idPessoa}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status === 200) {
            var result = await response.json();

            setDadosPessoa({
                tipoMembro: result.data?.tipoPessoaText,
                sexo: result.data?.generoText,
                estadoCivil: result.data?.estadoCivilText,
                nome: result.data?.nome,
                nacionalidade: result.data?.nacionalidade,
                naturalidade: result.data?.naturalidade,
                dataNascimento: dayjs(result.data?.dataNascimento),
                profissao: result.data?.profissao,
                cep: result.data?.cep,
                logradouro: result.data?.logradouro,
                numeroLogradouro: result.data?.numero,
                complemento: result.data?.complemento,
                bairro: result.data?.bairro,
                cidade: result.data?.cidade,
                uf: result.data?.uf,
                email: result.data?.email,
                telefoneCelular: result.data?.telefoneCelular,
                telefoneFixo: result.data?.telefoneFixo,
                flAlfabetizado: result.data?.flAlfabetizado,
                flMembroProfesso: result.data?.flMembroProfesso,
                flMembroMatriz: result.data?.flMembroMatriz,
                flAtivo: result.data?.flAtivo
            });
        }

        setContext(old => { return { ...old, isLoading: false } });
    }
    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-user-plus"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Detalhes da Pessoa - {dadosPessoa.nome} {dadosPessoa.flAtivo === true ? <button disabled className="btn btn-success">Ativo</button> : <button disabled className="btn btn-danger">Inativo</button>}</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <div className="row" style={{ width: '100%' }}>
                                    <div className='col-lg-6 col-12' >
                                        <span><b>Tipo Pessoa:</b> {dadosPessoa.tipoMembro}</span><br /><br />
                                        <span><b>Nome:</b> {dadosPessoa.nome}</span><br /><br />
                                        <span><b>Sexo:</b> {dadosPessoa.sexo}</span><br /><br />
                                        <span><b>Estado Civil:</b> {dadosPessoa.estadoCivil}</span><br /><br />
                                        <span><b>Data Nascimento:</b> {new Date(dadosPessoa.dataNascimento).toLocaleDateString()}</span><br /><br />
                                        <span><b>Nacionalidade:</b> {dadosPessoa.nacionalidade}</span><br /><br />
                                        <span><b>Naturalidade:</b> {dadosPessoa.naturalidade}</span><br /><br />
                                        <span><b>Profissão:</b> {dadosPessoa.profissao}</span><br /><br />
                                        <span><b>É alfabetizado?</b> {dadosPessoa.flAlfabetizado === true ? 'Sim' : 'Não'}</span><br /><br />
                                        <span><b>Telefone Celular:</b> {dadosPessoa.telefoneCelular}</span><br /><br />
                                        <span><b>Telefone Fixo:</b> {dadosPessoa.telefoneFixo}</span><br /><br />
                                    </div>
                                    <div className='col-lg-6 col-12' >
                                        <span><b>Email:</b> {dadosPessoa.email}</span><br /><br />
                                        <span><b>CEP:</b> {dadosPessoa.cep}</span><br /><br />
                                        <span><b>Logradouro:</b> {dadosPessoa.logradouro}</span><br /><br />
                                        <span><b>Número Logradouro:</b> {dadosPessoa.numeroLogradouro}</span><br /><br />
                                        <span><b>Complemento:</b> {dadosPessoa.complemento}</span><br /><br />
                                        <span><b>Bairro:</b> {dadosPessoa.bairro}</span><br /><br />
                                        <span><b>Cidade:</b> {dadosPessoa.cidade}</span><br /><br />
                                        <span><b>UF:</b> {dadosPessoa.uf}</span><br /><br />
                                        <span><b>É Membro Professo?</b> {dadosPessoa.flMembroProfesso === true ? 'Sim' : 'Não'}</span><br /><br />
                                        <span><b>É Membro Matriz?</b> {dadosPessoa.flMembroMatriz === true ? 'Sim' : 'Não'}</span><br /><br />
                                    </div>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div >
                </div >
            </div >
        </>
    )
}
