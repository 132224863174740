import React, { useContext, useEffect } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layouts/Layout';
import Protected from './components/Protected/Protected';
import { AppContext } from './contexts/AppContext';

function App() {
    const { context, setContext } = useContext(AppContext);

    const checkToken = () => {
        if (!context.token) {
            setContext(old => { return { ...old, isLoading: false, urlBotaoVoltar: null } });
            return;
        }

        fetch('/api/auth/session', { method: 'GET', headers: { 'Authorization': `Bearer ${context.token}` } })
            .then(response => {
                if (response.status != 200) {
                    setContext(old => { return { ...old, token: null, username: null, urlBotaoVoltar: null, profiles: [] } });
                }
            });
    };

    useEffect(() => {
        checkToken();
    }, []);

    return (
        <div className="wrapper">

            <div style={{ width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignContent: 'center', display: context.isLoading ? 'flex' : 'none' }}>
                <InfinitySpin width="200" color="#cc536d" visible={false} />
            </div>

            <div style={{ display: context.isLoading ? 'none' : 'block' }}>
                <BrowserRouter>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, requiredRoles, path } = route;

                            return <Route
                                key={index}
                                path={path}
                                element={
                                    <Protected context={context} requiredRoles={requiredRoles}>
                                        <Layout childEl={element} profile={context.profiles} />
                                    </Protected>
                                }
                            />;
                        })}
                    </Routes>
                </BrowserRouter>
            </div>
        </div>
    );
}

export default App;