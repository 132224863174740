import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import { React, useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';
import '../../css/NovaChamada.css';

const MySwal = withReactContent(Swal);
export default function NovaChamada() {
    const { context, setContext } = useContext(AppContext);
    const { idEvento } = useParams();

    const [listaPresenca, setListaPresenca] = useState([]);
    const [dataChamada, setDataChamada] = useState('');
    const [dadosEvento, setDadosEvento] = useState({ descricao: '', totalRows: 0 });

    async function fetchParticipantes() {
        setContext(old => { return { ...old, isLoading: true } });
        MySwal.close();

        var response = await fetch(`/api/eventos/participantes/${idEvento}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status != 404) {
            var result = await response.json();

            var oListaPresenca = (result.data.participantes || [])
                .map(x => ({ ...x, flPresente: false }));

            setListaPresenca(oListaPresenca);
            setDadosEvento({ descricao: result.data.descricao, totalRows: result.data.totalRows });
        }

        setContext(old => { return { ...old, isLoading: false } });
    }

    function toggleParticipantePresente(idParticipante) {
        let oListaPresenca = listaPresenca.slice();
        let participante = oListaPresenca.find(l => l.id == idParticipante);
        participante.flPresente = !participante.flPresente;
        setListaPresenca(oListaPresenca);
    }

    function cancelarChamada() {
        window.location.href = '/eventos';
    }

    async function finalizarChamada() {
        setContext(old => { return { ...old, isLoading: true } });

        var finalListaPresenca = listaPresenca.map(l => ({ flPresente: l.flPresente, idPessoa: l.id }));

        let formErrors = '';

        if (!dataChamada || !dataChamada.$d) {
            formErrors += '- Data da Chamada não preenchido <br/>';
        }

        if (!!formErrors) {
            MySwal.fire({
                icon: 'error',
                html: formErrors
            });

            setContext(old => { return { ...old, isLoading: false } });
            return;
        }

        var request = {
            idEvento: idEvento,
            listaPresenca: finalListaPresenca,
            dataChamada: dataChamada?.$d?.toJSON()
        };

        var response = await fetch(`/api/chamadas`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

        if (response.status === 200) {
            MySwal.fire({
                icon: 'success',
                html: <>Chamada feita com sucesso!</>
            }).then(() => {
                window.location.href = '/eventos'
            });
        } else {
            MySwal.fire({
                icon: 'error',
                html: <>Erro ao fazer chamada. <br />Contate o administrador do sistema.</>
            })
        }
        setContext(old => { return { ...old, isLoading: false } });
    }

    useEffect(() => {
        fetchParticipantes();
    }, [])

    const columns = [
        {
            name: 'Nome',
            selector: row => row.nome,
            sortable: true
        },
        {
            name: 'Presente?',
            selector: row => <button onClick={() => toggleParticipantePresente(row.id)} className={`btn btn-${!row.flPresente ? 'danger' : 'success'}`}>{row.flPresente === true ? 'Presente' : 'Ausente'}</button>,
            sortable: true,
            sortField: row => row.flPresente,
            sortFunction: (a, b) => a.flPresente - b.flPresente
        }
    ]

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-users"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Chamada - {dadosEvento.descricao}</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-body">
                            <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                                <button className="btn btn-danger" onClick={() => cancelarChamada()}>Cancelar Chamada</button>
                                <button className="btn btn-success" onClick={() => finalizarChamada()}>Finalizar Chamada</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-body">
                            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <div>
                                    <label>Data Chamada:</label>
                                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                        <DatePicker maxDate={dayjs()}
                                            onChange={(e) => {
                                                const newValue = e;
                                                setDataChamada(newValue);
                                            }}
                                            slotProps={{ textField: { size: 'small' } }} />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold logo-color">Lista de Matrículas ({dadosEvento.totalRows})</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive table-cursor-pointer">
                                <DataTable
                                    columns={columns}
                                    data={listaPresenca}
                                    noDataComponent={<>Nenhum registro encontrado</>}
                                    onRowClicked={(row, event) => toggleParticipantePresente(row.id)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}