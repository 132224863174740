import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import DataTable from 'react-data-table-component';
import { InfinitySpin } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';
import Select from 'react-select'

const MySwal = withReactContent(Swal);

export default function Participantes() {
    const { context, setContext } = useContext(AppContext);
    const { idEvento } = useParams();

    const [newParticipantesList, setNewParticipantesList] = useState([]);
    const [participantesList, setParticipantesList] = useState([]);
    const [listaMembros, setListaMembros] = useState([]);
    const [dadosEvento, setDadosEvento] = useState({ descricao: '', totalRows: 0, idPessoaMinistrador: '' });

    async function fetchParticipantes(isCadastro, isDelete) {
        MySwal.close();

        var response = await fetch(`/api/eventos/participantes/${idEvento}`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status != 404) {
            let result = await response.json();

            setParticipantesList(result.data.participantes || []);

            let { descricao, totalRows, idPessoaMinistrador } = result.data;

            setDadosEvento({ descricao, totalRows, idPessoaMinistrador });

            await getMembrosListNotInEvento(idPessoaMinistrador);

            if (isCadastro) {
                MySwal.fire({
                    html: <>Participante(s) <b style={{ color: "green" }}>cadastrado(s)</b> com sucesso!</>,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    toast: true
                });

                setNewParticipantesList([]);
            }

            if (isDelete) {
                MySwal.fire({
                    html: <>Participante <b style={{ color: "red" }}>removido</b> com sucesso!</>,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    toast: true
                });

                setNewParticipantesList([]);
            }

        }
    }

    async function getMembrosListNotInEvento(currentIdPessoaMinistrador) {

        let body = { idEventos: [idEvento], idPessoas: [] };

        if (currentIdPessoaMinistrador) {
            body.idPessoas.push(currentIdPessoaMinistrador);
        }

        let response = await fetch(`/api/membros/list/except`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

        if (response.status != 200) {
            MySwal.fire({
                icon: 'error',
                html: <>Erro ao obter lista de pessoas.<br />Contate o administrador do sistema.</>
            });
        } else {
            var result = await response.json();

            var membrosList = result.data.map(m => ({ label: m.nome, value: m.id }));

            setListaMembros(membrosList);
        }
    }

    async function cadastrarEventoParticipantes() {

        if (!newParticipantesList?.length) {
            return;
        }

        setContext(old => { return { ...old, isLoading: true } });

        var idsParticipantes = newParticipantesList.map(p => p.value);

        var request = {
            idEvento,
            idsParticipantes
        };

        var response = await fetch(`/api/eventos/participantes`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

        if (response.status != 200) {
            MySwal.fire({
                icon: 'error',
                html: <>Erro ao cadastrar novos participantes.<br />Contate o administrador do sistema.</>
            });
        } else {
            await fetchParticipantes(true);
        }

        setContext(old => { return { ...old, isLoading: false } });
    }

    async function removerEventoParticipantes(idEventoParticipante) {
        setContext(old => { return { ...old, isLoading: true } });

        var response = await fetch(`/api/eventos/participantes/${idEvento}/${idEventoParticipante}`,
            {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status != 200) {
            MySwal.fire({
                icon: 'error',
                html: <>Erro ao remover participante.<br />Contate o administrador do sistema.</>
            });
        } else {
            await fetchParticipantes(false, true);
        }

        setContext(old => { return { ...old, isLoading: false } });
    }

    useEffect(() => {
        setContext(old => { return { ...old, isLoading: true, urlBotaoVoltar: `/eventos` } });

        Promise.allSettled([fetchParticipantes()])
            .then(() => {
                setContext(old => { return { ...old, isLoading: false } });
            });
    }, [])

    const columns = useMemo(() => [
        {
            name: 'Nome',
            selector: row => row.nome,
            grow: 5,
            sortable: true
        },
        {
            name: 'Ações',
            grow: 1,
            selector: row => <>
                {row.flPossuiChamada ? <></> : <button title="Chamada" className="btn btn-danger" onClick={() => removerEventoParticipantes(row.id)}><i className="fa fa-trash-alt"></i></button>}
            </>,
            sortable: true
        }
    ], []);

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-users"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Matrículas - {dadosEvento.descricao}</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold logo-color">Novas Matrículas:</h6>
                        </div>
                        <div className="card-body">
                            <div style={{ display: 'flex' }}>
                                <div className="col-10">
                                    <Select
                                        isMulti
                                        options={listaMembros}
                                        value={newParticipantesList}
                                        onChange={(e) => setNewParticipantesList(e)}
                                        name="novos-participantes"
                                        placeholder="Selecione..."
                                    />
                                </div>
                                <div className="col-2">
                                    <button className="btn btn-success" onClick={() => cadastrarEventoParticipantes()}>Cadastrar</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-header py-3">
                            <h6 className="m-0 font-weight-bold logo-color">Lista de Matrículas ({dadosEvento.totalRows})</h6>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <DataTable
                                    columns={columns}
                                    data={participantesList}
                                    noDataComponent={<>Nenhum registro encontrado</>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}