import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { InfinitySpin } from 'react-loader-spinner';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';
import '../../css/Membros.css';

const MySwal = withReactContent(Swal);

export default function NovoMembro() {
    const { context, setContext } = useContext(AppContext);

    const [pessoas, setPessoas] = useState([]);
    const [tiposList, setTiposList] = useState({
        tiposMembro: [],
        tiposGenero: [],
        tiposEstadoCivil: []
    });

    const [dadosPessoa, setDadosPessoa] = useState({
        tipoMembro: '',
        nome: '',
        sexo: '',
        nacionalidade: '',
        naturalidade: '',
        dataNascimento: '',
        conjuge: '',
        pai: '',
        mae: '',
        profissao: '',
        cep: '',
        logradouro: '',
        numeroLogradouro: '',
        complemento: '',
        bairro: '',
        cidade: '',
        uf: '',
        email: '',
        telefoneCelular: '',
        telefoneFixo: '',
        estadoCivil: '',
        flAlfabetizado: '',
        flMembroProfesso: '',
        flMembroMatriz: ''
    });

    const inputNumeroLogradouro = useRef();

    const maskCelular = [{ mask: '(00) 00000-0000' }];
    const maskFixo = [{ mask: '(00) 0000-0000' }];

    useEffect(() => {
        setContext(old => ({ ...old, isLoading: true }));

        Promise.allSettled([getMembrosList(), fetchTipos()])
            .then(() => {
                setContext(old => ({ ...old, isLoading: false }));
            });
    }, []);

    async function fetchTipos() {
        var response = await fetch(`/api/membros/tipos`,
            {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                }
            });

        if (response.status == 200) {
            var result = await response.json();

            if (result != null) {
                let tiposPessoa = result.tiposPessoa;
                let generos = result.generos;
                let estadosCivis = result.estadosCivis;

                if (!!tiposPessoa?.length || !!generos?.length || !!estadosCivis?.length) {
                    setTiposList({
                        tiposMembro: tiposPessoa ?? [],
                        tiposGenero: generos ?? [],
                        tiposEstadoCivil: estadosCivis ?? []
                    });
                }
            }
        }
    }

    async function setCepBuscaViaCep(cep) {
        setDadosPessoa(old => ({ ...old, cep }));

        var cepNumerics = cep.replace(/\D/g, '');

        if (!cepNumerics || cepNumerics.length != 8) {
            return;
        }

        setContext(old => { return { ...old, isLoading: true } });

        var response = await fetch(`https://viacep.com.br/ws/${cepNumerics}/json/`);

        if (response.status == 200) {
            var result = await response.json();

            setDadosPessoa(old => ({
                ...old,
                logradouro: result.logradouro,
                complemento: result.complemento,
                bairro: result.bairro,
                cidade: result.localidade,
                uf: result.uf
            }));

            inputNumeroLogradouro.current.focus();
            setContext(old => { return { ...old, isLoading: false } });
        }
    }

    function getFormErrors() {
        let errors = '';

        if (!dadosPessoa.tipoMembro) {
            errors += '- Tipo de Pessoa não preenchido <br/>';
        }

        if (!dadosPessoa.nome) {
            errors += '- Nome não preenchido <br/>';
        }

        if (!dadosPessoa.sexo) {
            errors += '- Sexo não preenchido <br/>';
        }

        if (!dadosPessoa.estadoCivil) {
            errors += '- Estado Civil não preenchido <br/>';
        }

        if (!dadosPessoa.dataNascimento || !dadosPessoa.dataNascimento.$d) {
            errors += '- Data de Nascimento não preenchido <br/>';
        }

        if (!dadosPessoa.nacionalidade) {
            errors += '- Nacionalidade não preenchida <br/>';
        }

        if (dadosPessoa.flAlfabetizado === null || dadosPessoa.flAlfabetizado === undefined || dadosPessoa.flAlfabetizado === '') {
            errors += '- Não informado se é alfabetizado <br/>';
        }

        if (!dadosPessoa.telefoneCelular) {
            errors += '- Telefone Celular não preenchido <br/>';
        }

        if (!dadosPessoa.cep) {
            errors += '- CEP não preenchido <br/>';
        }

        if (!dadosPessoa.logradouro) {
            errors += '- Logradouro não preenchido <br/>';
        }

        if (!dadosPessoa.numeroLogradouro) {
            errors += '- Número Logradouro não preenchido <br/>';
        }

        if (!dadosPessoa.bairro) {
            errors += '- Bairro não preenchido <br/>';
        }

        if (!dadosPessoa.cidade) {
            errors += '- Cidade não preenchido <br/>';
        }

        if (!dadosPessoa.uf) {
            errors += '- UF não preenchido <br/>';
        }

        if (dadosPessoa.flMembroProfesso === null || dadosPessoa.flMembroProfesso === undefined || dadosPessoa.flMembroProfesso === '') {
            errors += '- Não informado se é membro professo <br/>';
        }

        if (dadosPessoa.flMembroMatriz === null || dadosPessoa.flMembroMatriz === undefined || dadosPessoa.flMembroMatriz === '') {
            errors += '- Não informado se é membro matriz <br/>';
        }

        return errors;
    }

    async function getMembrosList() {
        try {
            var response = await fetch(`/api/membros/list`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${context.token}`,
                        'Content-Type': 'application/json'
                    }
                });

            if (response.status != 200) {
                MySwal.fire({
                    icon: 'error',
                    html: <>Erro ao obter lista de pessoas.<br />Contate o administrador do sistema.</>
                });
            } else {
                var result = await response.json();

                var membrosList = result.data.map(m => ({ label: m.nome, value: m.id }));

                setPessoas(membrosList);
            }
        } finally {
            setContext(old => { return { ...old, isLoading: false, urlBotaoVoltar: null } });
        }
    }

    async function submitCreateMembro() {
        setContext(old => { return { ...old, isLoading: true } });

        var formErrors = getFormErrors();

        if (!!formErrors) {
            MySwal.fire({
                icon: 'error',
                html: formErrors
            });

            setContext(old => { return { ...old, isLoading: false } });
            return;
        }

        var request = {
            tipoMembro: dadosPessoa.tipoMembro,
            nome: dadosPessoa.nome,
            sexo: dadosPessoa.sexo,
            estadoCivil: dadosPessoa.estadoCivil,
            dataNascimento: dadosPessoa.dataNascimento?.$d?.toJSON(),
            nacionalidade: dadosPessoa.nacionalidade,
            naturalidade: dadosPessoa.naturalidade,
            profissao: dadosPessoa.profissao,
            flAlfabetizado: dadosPessoa.flAlfabetizado,
            telefoneCelular: dadosPessoa.telefoneCelular,
            telefoneFixo: dadosPessoa.telefoneFixo,
            email: dadosPessoa.email,
            cep: dadosPessoa.cep,
            logradouro: dadosPessoa.logradouro,
            numeroLogradouro: dadosPessoa.numeroLogradouro,
            complemento: dadosPessoa.complemento,
            bairro: dadosPessoa.bairro,
            cidade: dadosPessoa.cidade,
            uf: dadosPessoa.uf,
            flMembroProfesso: dadosPessoa.flMembroProfesso,
            flMembroMatriz: dadosPessoa.flMembroMatriz,
            idPessoaConjuge: dadosPessoa.conjuge?.value,
            idPessoaPai: dadosPessoa.pai?.value,
            idPessoaMae: dadosPessoa.mae?.value
        };

        var response = await fetch(`/api/membros`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${context.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(request)
            });

        var result = await response.json();

        if (response.status !== 200) {

            var errors = result.errors || [];

            var beautifiedErrors = '';

            if (errors && errors.length) {
                beautifiedErrors = '- ' + errors.join('<br/> - ');
            }

            MySwal.fire({
                icon: 'error',
                html: beautifiedErrors || 'Erro interno ao salvar membro'
            });

            setContext(old => { return { ...old, isLoading: false } });
            return;
        }

        MySwal.fire({
            icon: 'success',
            html: result.message
        }).then(() => {
            window.location.href = '/pessoas'
        });

        setContext(old => { return { ...old, isLoading: false } });
    }

    return (
        <>
            <div style={{ width: '100%', height: '80vh', display: !context.isLoading ? 'none' : 'flex', justifyContent: 'center', alignContent: 'center' }}>
                <InfinitySpin width='200' color="#37b620" visible={false} />
            </div>

            <div style={{ display: !context.isLoading ? '' : 'none' }}>
                <div className="d-sm-flex align-items-center  mb-4">
                    <i className="fa fa-user-plus"></i>&nbsp;&nbsp;
                    <h1 className="h3 mb-0 text-gray-800">Nova Pessoa</h1>
                </div>

                <div className="row">
                    <div className="card shadow mb-4 p-0">
                        <div className="card-body" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                                <div className="row" style={{ width: '100%' }}>
                                    <div className='col-lg-2 col-12' >
                                        <label>Tipo Pessoa:</label>
                                        <select className="form-control" value={dadosPessoa.tipoMembro}
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, tipoMembro: newValue }));
                                            }}
                                        >
                                            <option value="">Selecione...</option>
                                            {tiposList.tiposMembro.map(t => <option key={t.value} value={t.value}>{t.label}</option>)}
                                        </select>
                                    </div>
                                    <div className='col-lg-4 col-12 mt-2 mt-lg-0' >
                                        <label>Nome:</label>
                                        <input type="text" placeholder="Nome" className="form-control"
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, nome: newValue }));
                                            }}
                                            value={dadosPessoa.nome}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-12 mt-2 mt-lg-0' >
                                        <label>Sexo:</label>
                                        <select className="form-control" value={dadosPessoa.sexo}
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, sexo: newValue }));
                                            }}
                                        >
                                            <option value="">Selecione...</option>
                                            {tiposList.tiposGenero.map(t => <option key={t.value} value={t.value}>{t.label}</option>)}
                                        </select>
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Estado Civil:</label>
                                        <select className="form-control" value={dadosPessoa.estadoCivil}
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, estadoCivil: newValue }));
                                            }}
                                        >
                                            <option value="">Selecione...</option>
                                            {tiposList.tiposEstadoCivil.map(t => <option key={t.value} value={t.value}>{t.label}</option>)}
                                        </select>
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Data Nascimento:</label>
                                        <DatePicker maxDate={dayjs()}
                                            onChange={(e) => {
                                                const newValue = e;
                                                setDadosPessoa(old => ({ ...old, dataNascimento: newValue }));
                                            }}
                                            slotProps={{ textField: { size: 'small' } }} />
                                    </div>
                                </div>
                                <div className="row mt-4" style={{ width: '100%' }}>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0'>
                                        <label>Cônjuge:</label>
                                        <Select
                                            options={pessoas}
                                            value={dadosPessoa.conjuge}
                                            onChange={(e) => {
                                                const newValue = e;
                                                setDadosPessoa(old => ({ ...old, conjuge: newValue }));
                                            }}
                                            name="conjuge"
                                            isSearchable={true}
                                            placeholder="Selecione..." />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0'>
                                        <label>Pai:</label>
                                        <Select
                                            options={pessoas}
                                            value={dadosPessoa.pai}
                                            onChange={(e) => {
                                                const newValue = e;
                                                setDadosPessoa(old => ({ ...old, pai: newValue }));
                                            }}
                                            name="pai"
                                            isSearchable={true}
                                            placeholder="Selecione..." />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0'>
                                        <label>Mãe:</label>
                                        <Select
                                            options={pessoas}
                                            value={dadosPessoa.mae}
                                            onChange={(e) => {
                                                const newValue = e;
                                                setDadosPessoa(old => ({ ...old, mae: newValue }));
                                            }}
                                            name="mae"
                                            isSearchable={true}
                                            placeholder="Selecione..." />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Nacionalidade:</label>
                                        <input type="text" placeholder="Nacionalidade" className="form-control" value={dadosPessoa.nacionalidade}
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, nacionalidade: newValue }));
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Naturalidade:</label>
                                        <input type="text" placeholder="Naturalidade" className="form-control" value={dadosPessoa.naturalidade}
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, naturalidade: newValue }));
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Profissão:</label>
                                        <input type="text" placeholder="Profissão" className="form-control" value={dadosPessoa.profissao}
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, profissao: newValue }));
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="row mt-4" style={{ width: '100%' }}>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>É Alfabetizado?</label>
                                        <select className="form-control" value={dadosPessoa.flAlfabetizado} onChange={(e) => {
                                            if (e.currentTarget.value == 'true') {
                                                setDadosPessoa(old => ({ ...old, flAlfabetizado: true }));
                                            } else if (e.currentTarget.value == 'false') {
                                                setDadosPessoa(old => ({ ...old, flAlfabetizado: false }));
                                            } else {
                                                setDadosPessoa(old => ({ ...old, flAlfabetizado: null }));
                                            }
                                        }}>
                                            <option value="">Selecione...</option>
                                            <option value={true}>Sim</option>
                                            <option value={false}>Não</option>
                                        </select>
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Telefone Celular:</label>
                                        <IMaskInput mask={maskCelular} name="telefone-celular" placeholder="(__) ____-____" className="form-control"
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, telefoneCelular: newValue }));
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Telefone Fixo (opcional):</label>
                                        <IMaskInput mask={maskFixo} name="telefone-fixo" placeholder="(__) ____-____" className="form-control"
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, telefoneFixo: newValue }));
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Email (opcional):</label>
                                        <input type="text" placeholder="Email" className="form-control" value={dadosPessoa.email}
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, email: newValue }));
                                            }}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>CEP:</label>
                                        <IMaskInput mask={[{ mask: '00000-000' }]} name="cep" placeholder="_____-___" className="form-control"
                                            onChange={(e) => setCepBuscaViaCep(e.currentTarget.value)}
                                            value={dadosPessoa.cep}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Logradouro:</label>
                                        <input type="text" placeholder="Logradouro" className="form-control"
                                            onChange={(e) => {
                                                const newValue = (e.currentTarget.value || '').toString();
                                                setDadosPessoa(old => ({ ...old, logradouro: newValue }));
                                            }}
                                            value={dadosPessoa.logradouro}
                                        />
                                    </div>
                                </div>
                                <div className="row mt-4" style={{ width: '100%' }}>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Número Logradouro:</label>
                                        <input type="text" placeholder="Número Logradouro" className="form-control" ref={inputNumeroLogradouro}
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, numeroLogradouro: newValue }));
                                            }}
                                            value={dadosPessoa.numeroLogradouro}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Complemento:</label>
                                        <input type="text" placeholder="Complemento" className="form-control"
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, complemento: newValue }));
                                            }}
                                            value={dadosPessoa.complemento}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Bairro:</label>
                                        <input type="text" placeholder="Bairro" className="form-control"
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, bairro: newValue }));
                                            }}
                                            value={dadosPessoa.bairro}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>Cidade:</label>
                                        <input type="text" placeholder="Cidade" className="form-control"
                                            onChange={(e) => {
                                                const newValue = e.currentTarget.value;
                                                setDadosPessoa(old => ({ ...old, cidade: newValue }));
                                            }}
                                            value={dadosPessoa.cidade}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>UF:</label>
                                        <IMaskInput mask={[{ mask: 'aa' }]} name="uf" placeholder="UF" className="form-control"
                                            onChange={(e) => {
                                                const newValue = (e.currentTarget.value ?? '').toUpperCase();
                                                setDadosPessoa(old => ({ ...old, uf: newValue }));
                                            }}
                                            value={dadosPessoa.uf}
                                        />
                                    </div>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>É Membro Professo?</label>
                                        <select className="form-control" value={dadosPessoa.flMembroProfesso} onChange={(e) => {
                                            if (e.currentTarget.value == 'true') {
                                                setDadosPessoa(old => ({ ...old, flMembroProfesso: true }));
                                            } else if (e.currentTarget.value == 'false') {
                                                setDadosPessoa(old => ({ ...old, flMembroProfesso: false }));
                                            } else {
                                                setDadosPessoa(old => ({ ...old, flMembroProfesso: null }));
                                            }
                                        }}>
                                            <option value="">Selecione...</option>
                                            <option value={true}>Sim</option>
                                            <option value={false}>Não</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mt-4" style={{ width: '100%' }}>
                                    <div className='col-lg-2 col-md-6 col-12 mt-2 mt-lg-0' >
                                        <label>É Membro Matriz?</label>
                                        <select className="form-control" value={dadosPessoa.flMembroMatriz} onChange={(e) => {
                                            if (e.currentTarget.value == 'true') {
                                                setDadosPessoa(old => ({ ...old, flMembroMatriz: true }));
                                            } else if (e.currentTarget.value == 'false') {
                                                setDadosPessoa(old => ({ ...old, flMembroMatriz: false }));
                                            } else {
                                                setDadosPessoa(old => ({ ...old, flMembroMatriz: null }));
                                            }
                                        }}>
                                            <option value="">Selecione...</option>
                                            <option value={true}>Sim</option>
                                            <option value={false}>Não</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row" style={{ width: '100%', marginTop: '3.5rem' }}>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2 mt-lg-0 text-right' >
                                        <button className="btn btn-danger" onClick={() => window.location.href = '/pessoas'}>Cancelar</button>
                                    </div>
                                    <div className='col-lg-6 col-md-6 col-12 mt-2 mt-lg-0 text-left' >
                                        <button className="btn btn-success" onClick={() => submitCreateMembro()}>Salvar</button>
                                    </div>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
