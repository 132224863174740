import React, { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { AppContext } from '../../contexts/AppContext';
import logo from '../../img/ippc.png';

import '../../css/Login.css';

const MySwal = withReactContent(Swal)

export default function Login() {
    const [iUsername, setIUserName] = useState();
    const [password, setPassword] = useState();

    const [loginError, setLoginError] = useState(false);

    const { setContext } = useContext(AppContext);

    const handleSubmit = async e => {
        e.preventDefault();
        setContext(old => { return { ...old, isLoading: true } });

        loginUser({ username: iUsername, password })
            .then(result => {
                if (result.status !== 423) {
                    return result.json();
                } else {
                    MySwal.fire({
                        icon: 'error',
                        title: <>Limite de tentativas excedido.<br /> A conta foi bloqueada.
                            <br /><br />
                            Contate o administrador do sistema.
                        </>
                    });

                    setLoginError(false);
                }
            })
            .then(result => {
                if (result) {
                    if (result.success === true) {

                        var profilesLowerCase = (result.profiles ?? '').split('|').map(x => x.toLowerCase());

                        setContext(old => { return { ...old, token: result.token, username: iUsername, profiles: (profilesLowerCase || '') } });

                        setLoginError(false);
                    } else {
                        setContext(old => { return { ...old, token: null, username: null, profiles: [] } });

                        setLoginError(result.message);
                    }
                }
            })
            .catch(err => {
                MySwal.fire({
                    icon: 'error',
                    title: <>Erro interno. <br />Caso persista, contate o administrador do sistema.</>
                });

                console.log(err);

                setLoginError(false);
            })
            .finally(() => {
                setContext(old => { return { ...old, isLoading: false } });
            });
    }

    const loginUser = (credentials) => {
        return fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
    }

    return (
        <>
            <div className="limiter">
                <div className="container-login100" id='bgImgCity'>
                    <div className="wrap-login100">
                        <form onSubmit={handleSubmit} className="login100-form validate-form">
                            <span className="login100-form-logo">
                                <img src={logo} className="ippc-logo login-page" alt="Logo da Igreja Presbiteriana de Pocos de Caldas" />

                            </span>

                            <span className="login100-form-title mt-3 mb-3">
                                Login
                            </span>

                            <div style={{ display: !loginError ? 'none' : '', textAlign: 'center', color: 'red', padding: '20px 0' }}>
                                <span>{loginError}</span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Enter username">
                                <input className="input100" type="text" name="username" placeholder="Username"
                                    onChange={e => setIUserName(e.currentTarget.value)} />
                                <span className="focus-input100" data-placeholder="&#xf207;"></span>
                            </div>

                            <div className="wrap-input100 validate-input" data-validate="Enter password">
                                <input className="input100" type="password" name="pass" placeholder="Password"
                                    onChange={e => setPassword(e.currentTarget.value)} />
                                <span className="focus-input100" data-placeholder="&#xf191;"></span>
                            </div>

                            <div className="container-login100-form-btn">
                                <button type='submit' className="login100-form-btn">
                                    Login
                                </button>
                            </div>

                        </form>
                    </div>
                </div>

                <footer className='footer-login'>
                    © IPPC 2023 - All rights reserved
                </footer>
            </div>
        </>
    )
}
